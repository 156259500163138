var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('titulo-seccion',[_c('template',{slot:"area-rutas"},[_c('a',{attrs:{"href":"/"}},[_vm._v("Inicio")]),_vm._v(" / "),_c('span',{staticClass:"active"},[_vm._v("Eventos")])]),_c('template',{slot:"area-titulo"},[(_vm.item)?_c('h3',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")]):_vm._e()])],2),_c('div',{staticClass:"container",attrs:{"id":"content-page"}},[_c('b-row',{attrs:{"cols":"12"}},[_c('b-col',{attrs:{"lg":"4"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-title',{staticClass:"mb-25"},[_c('b-button',{style:({
                '--background-color-btn':
                  _vm.config.disenio.datos.color_secundario,
              }),attrs:{"variant":"primary btn-fiscalia","block":"","to":"/eventos"}},[_vm._v(" Eventos")])],1)],1),_c('b-list-group',[_vm._l((_vm.itemsmenu),function(item){return [_c('b-list-group-item',{key:item.entrada.id},[_c('router-link',{staticClass:"text-secondary",staticStyle:{"text-decoration":"none"},style:({
                  '--color-secondary': _vm.config.disenio.datos.color_secundario,
                }),attrs:{"to":'/evento/' + item.entrada.url,"exact-path":""}},[_vm._v(_vm._s(item.entrada.titulo))])],1)]})],2)],1),_c('b-col',{attrs:{"lg":"8"}},[(_vm.item.entrada)?_c('div',{},[(_vm.item.entrada.imagen)?_c('div',{staticClass:"img-portada lg-round"},[_c('b-img-lazy',{attrs:{"src":_vm.item.entrada.imagen,"alt":"","fluid-grow":""}})],1):_vm._e(),_c('div',{staticClass:"mt-4"},[_c('h3',{staticClass:"titulo text-secondary",style:({
                '--color-secondary': _vm.config.disenio.datos.color_secundario,
              }),attrs:{"data-aos":"fade-up"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.item.entrada.titulo)+" ")])]),_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"me-1"},[_c('b-avatar',{attrs:{"rounded":"","variant":"primary","size":"40"}},[_c('feather-icon',{staticClass:"text-secondary",style:({
                      '--color-secondary':
                        _vm.config.disenio.datos.color_secundario,
                    }),attrs:{"icon":"CalendarIcon","size":"20"}})],1)],1),_c('b-media-body',[_c('h6',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.item.fecha)+" ")]),(_vm.item.hora_inicio)?_c('small',[_vm._v(" "+_vm._s(_vm.item.hora_inicio)+" a "+_vm._s(_vm.item.hora_final))]):_c('small',[_vm._v(" Todo el Dia ")])])],1),_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"me-1"},[_c('b-avatar',{attrs:{"rounded":"","variant":"primary","size":"40"}},[_c('feather-icon',{staticClass:"text-secondary",style:({
                      '--color-secondary':
                        _vm.config.disenio.datos.color_secundario,
                    }),attrs:{"icon":"MapPinIcon","size":"20"}})],1)],1),_c('b-media-body',[_c('h6',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.item.ciudad)+" ")]),_c('small',[_vm._v(" "+_vm._s(_vm.item.nombre_lugar)+", "+_vm._s(_vm.item.direccion)+" ")])])],1),_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"me-1"},[_c('b-avatar',{attrs:{"rounded":"","variant":"primary","size":"40"}},[_c('feather-icon',{staticClass:"text-secondary",style:({
                      '--color-secondary':
                        _vm.config.disenio.datos.color_secundario,
                    }),attrs:{"icon":"PhoneIcon","size":"20"}})],1)],1),_c('b-media-body',[_c('h6',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.item.telefono)+" ")]),_c('span',[_vm._v(" ")])])],1),_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"me-1"},[_c('b-avatar',{attrs:{"rounded":"","variant":"primary","size":"40"}},[_c('feather-icon',{staticClass:"text-secondary",style:({
                      '--color-secondary':
                        _vm.config.disenio.datos.color_secundario,
                    }),attrs:{"icon":"UsersIcon","size":"20"}})],1)],1),_c('b-media-body',[_c('h6',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.item.nombre_organizador)+" ")]),_c('small',[_vm._v(" "+_vm._s(_vm.item.telefono_organizador)+" ")])])],1),_c('div',{staticClass:"mt-2",attrs:{"data-aos":"fade-up"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.item.entrada.descripcion)}}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.item.entrada.contenido)}})])],1)]):_vm._e(),_c('div',{staticClass:"mt-4"},[_c('l-map',{staticStyle:{"height":"500px","width":"100%"},attrs:{"zoom":_vm.zoom,"options":_vm.mapOptions,"center":_vm.center},on:{"update:zoom":function($event){_vm.zoom=$event}}},[_c('l-tile-layer',{attrs:{"url":_vm.url,"attribution":_vm.attribution}}),_c('v-marker-cluster',{attrs:{"options":_vm.clusterOptions},on:{"clusterclick":function($event){return _vm.click()},"ready":_vm.ready}},_vm._l((_vm.locations),function(l){return _c('l-marker',{key:l.id,attrs:{"lat-lng":l.latlng,"icon":l.icon}},[_c('l-popup',{attrs:{"content":l.text}})],1)}),1)],1)],1)])],1),_c('div',{staticClass:"mt-5 mb-5 row",attrs:{"id":"navegacion"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }